import React, {ChangeEvent, KeyboardEvent, useEffect, useState} from 'react';
import {isValid, ValidationOption} from "@Util/formUtils"; // 올바르게 import

export type InputType = 'text' | 'password' | 'checkbox' | 'select' | 'subTitle' | 'email';

export interface FormFieldProps {
    inputType: InputType;
    name?: string; // 추가: input name을 사용하여 데이터 업데이트
    placeholder?: string;
    label?: React.ReactNode;
    rightLabel?: React.ReactNode;
    options?: { value: string; label: string }[];
    onChange?: (event: React.ChangeEvent<any>) => void;
    disable?:boolean
    displayNone?:boolean
}

interface FormFieldComponentProps {
    formFieldList: FormFieldProps[];
    onKeyDown?: (event: KeyboardEvent<HTMLInputElement | HTMLSelectElement>) => void;
    data: any; // data의 타입을 명확히 정의합니다
    setData: React.Dispatch<React.SetStateAction<any>>; // useState에서 사용하는 setState 함수의 타입
    validOption?: ValidationOption[];
}

const FormField = ({formFieldList, onKeyDown, data, setData, validOption}: FormFieldComponentProps) => {

    const [checkField, setCheckField] = useState<string>('')
    const handleKeyDown = (event: KeyboardEvent<HTMLInputElement | HTMLSelectElement>) => {
        if (event.key === 'Enter') {
            event.preventDefault(); // 기본 엔터키 동작 방지 (예: 폼 제출)
            if (onKeyDown) {
                onKeyDown(event);
            }
        }
    };

    const handleChange = (event: ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
        const { name, value } = event.target;
        setData({
            ...data,
            [name.replace('form', '')]: value
        });
    };


    useEffect(() => {
        if (checkField) {
            isValid(data, validOption || [], false, checkField)
        }
        setCheckField('')
    }, [checkField, data]);

    return (
        <>
            {formFieldList.map((field, index) => (
                <div key={index}   id={'form' + field.name} style={{display:field.displayNone?'none':'block'}}>
                    {field.label && field.inputType !== 'subTitle' &&
                        <label><p>{field.label}<span id={'form-message-' + field.name}></span></p>{field?.rightLabel}
                        </label>}
                    {field.inputType === 'select' && (
                        <select
                            aria-placeholder={field.placeholder}
                            defaultValue={field.name ? data[field.name] || '' : ''}
                            onChange={(e) => {
                                if (field.onChange) field.onChange(e);
                                handleChange(e)
                                setCheckField(field.name || '')
                            }}
                            onKeyDown={handleKeyDown}
                            name={'form' + field.name} // name 추가
                            id={'form-input-' + field.name}
                            disabled={field.disable}
                        >
                            <option value={''}>{field.placeholder}</option>
                            {field.options?.map((option, idx) => (
                                <option key={idx} value={option.value}>
                                    {option.label}
                                </option>
                            ))}
                        </select>
                    )}

                    {field.inputType === 'subTitle' && (
                        <h3>{field.label}</h3>
                    )}

                    {/*{field.inputType === 'checkbox' && (*/}
                    {/*    <select*/}
                    {/*        aria-placeholder={field.placeholder}*/}
                    {/*        defaultValue={field.name ? data[field.name] || '' : ''}*/}
                    {/*        onChange={handleChange}*/}
                    {/*        onKeyDown={handleKeyDown}*/}
                    {/*        name={'form'+field.name} // name 추가*/}
                    {/*    >*/}
                    {/*        {field.options?.map((option, idx) => (*/}
                    {/*            <option key={idx} value={option.value}>*/}
                    {/*                {option.label}*/}
                    {/*            </option>*/}
                    {/*        ))}*/}
                    {/*    </select>*/}
                    {/*)}*/}


                    {(field.inputType === 'text' || field.inputType === 'password' || field.inputType === 'email') && (
                        <input
                            type={field.inputType}
                            placeholder={field.placeholder}
                            defaultValue={field.name ? data[field.name] || '' : ''}
                            onChange={(e) => {
                                if (field.onChange) field.onChange(e);
                                handleChange(e)
                                setCheckField(field.name || '')
                            }}
                            onKeyDown={handleKeyDown}
                            name={'form' + field.name} // name 추가
                            id={'form-input-' + field.name}
                            disabled={field.disable}
                        />
                    )}
                </div>
            ))}
        </>
    );
};

export default FormField;
