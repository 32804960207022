import {getImageList} from "@Util/imageUtils";
import {saveAdminBoardApi} from "@Api/admin/board";
import {ProductDto} from "@Types/product";
import {saveBoardApi} from "@Api/user/board";

export const saveBoard = async (saveDto: ProductDto.Save,
                                fileMap: Map<number, File>, isAdmin?: boolean) => {
    const formData = getImageList(fileMap)
    formData.append('saveDto', JSON.stringify(saveDto))

    isAdmin ? await saveAdminBoardApi(formData) : await saveBoardApi(formData)
}


// export const fetchBoardList = async (
//     searchQuery: BoardSearchDTO,
//     setBoardList: React.Dispatch<React.SetStateAction<any[]>>,
//     setTotalPage: React.Dispatch<React.SetStateAction<number>>,
//     setTotalCount?: React.Dispatch<React.SetStateAction<number>> | null,
//     setNoticeCount?: React.Dispatch<React.SetStateAction<number>> | null
// ) => {
//     const boardListDTO = await getBoardListService(searchQuery)
//
//     setBoardList(boardListDTO?.boardList || [])
//     if (boardListDTO) {
//         const totalCount = boardListDTO.totalCount || 0
//         setTotalPage(Math.ceil(totalCount / 10) || 1)
//     } else {
//         setTotalPage(1)
//     }
//
//     if (setTotalCount) {
//         setTotalCount(boardListDTO?.totalCount || 0)
//     }
//     if (setNoticeCount) {
//         setNoticeCount(boardListDTO?.noticeCount || 0)
//     }
// }
