import React from 'react'
import styles from './index.module.scss'

export const PageBar = (props: {
    rowSize:number
    totalCount:number
    page:number
    setPage: React.Dispatch<React.SetStateAction<number>>
}): JSX.Element => {
    const boardPage = props.page
    const totalCount = props.totalCount
    const totalPage = Math.ceil(totalCount / 5)
    const totalPages = totalPage < 1 ? 1 : totalPage
    const startPage = Math.floor((boardPage - 1) / 5) * 5 + 1
    const endPage = Math.min(startPage + 4, totalPages)

    return (
        <div className={styles.pageBar}>
            <ul>
                <li>
                    <a
                        onClick={() => {
                            if (props.page > 1) {
                                props.setPage(props.page - 1)
                            } else {
                                props.setPage(1)
                            }
                        }}
                    >
                        <i className="icon-angle-left"/>
                    </a>
                </li>

                {[...Array(endPage - startPage + 1)].map((_, index) => {
                    const page = startPage + index
                    return (
                        <li key={index}>
                            {/* 현재 페이지와 링크된 페이지가 같으면 'current' 클래스 추가 */}
                            <a
                                id={page === boardPage ? 'current' : ''}
                                onClick={() => {
                                    props.setPage(page)
                                }}
                            >
                                {page}
                            </a>
                        </li>
                    )
                })}

                <li>
                    <a
                        onClick={() => {
                            if (props.page < totalPages) {
                                props.setPage(props.page + 1)
                            }
                        }}
                    >
                        <i className="icon-angle-right"/>
                    </a>
                </li>
            </ul>
        </div>
    )
}
