import React from "react";
import styles from "./index.module.scss"
import {Link} from "react-router-dom";
import ImageTag from "@Component/common/Image/ImageTag";

function Footer() {
    const bizCommPop: string = "https://www.ftc.go.kr/bizCommPop.do?wrkr_no=" + process.env.BUSINESS_NUMBER?.replaceAll('-', '')
    // 상호 , 대표 , 사업자번호 , 주소 ,통신판매업 신고 번호 , 이메일 , 문의 전화번호 , 사이트 명

    const logo = process.env.LOGO_IMAGE_BASE_URL! + 'logo.png';

    return (<footer className={styles.footer}>
        <div className={styles.document}>
            <Link to={'/shopinfo'}>회사소개</Link>|
            <Link to={'/privacy'}>개인정보처리방침</Link>|
            <Link to={'/agreement'}>이용약관</Link>
        </div>
        <hr/>
        <div >
        <div className={styles.image}>
            <ImageTag src={logo} alt={'사이트 로고'}/>
        </div>
        <div className={styles.info}>
            <div>
                <span>상호 : {process.env.COMPANY_NAME}</span>
                <span>대표 : {process.env.REPRESENTATIVE} &nbsp;&nbsp;</span>
            </div>
            <div><span>사업자 번호 : {process.env.BUSINESS_NUMBER}</span>
                <span><Link to={bizCommPop} target="_blank">사업자번호 확인</Link></span>
            </div>
            <div>
                <span>주소 : {process.env.ADDRESS}</span>
            </div>
            <div>
                <span>대표 전화번호 : {process.env.PHONE}</span>
            </div>
            <div>
                <span>이메일 : {process.env.EMAIL}</span>
            </div>
            <div>
                <span>통신판매업 신고 : {process.env.TRADE_REGISTRATION_NUMBER}</span>
            </div>
            <div>
                <span>COPYRIGHT © {process.env.SITE_FULL_NAME} Design by Hako</span>
            </div>
        </div>
    </div></footer>);
}

export default Footer;
