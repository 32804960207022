// DefaultSettings.ts

// Default setting for BoardDto.Save
import {BoardDto} from "@Types/board/boardDto";

export const createBoardSaveDto = (categoryId:number, productId?:string): BoardDto.Save => ({
    boardId: '',
    category: categoryId,
    title: '',
    content: '',
    reviewRating: '0',
    productId: productId,
    isHidden: false
});

// Default setting for BoardDto.Search
export const createBoardSearchDto = (): BoardDto.Search => ({
    category: 0,
    page: 0,
    rowSize: 10,
    productId: '',
    userId: 0,
    answer: ''
});

