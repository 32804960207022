import {getModalInstance} from "@Component/common/Modal/ModalProvider";
import {HttpAlert, HttpSuccess} from "@Types/cmm/HttpCallBack";
import {deleteRequest, getRequest, postRequest} from "@Api/Api";
import {BoardDto} from "@Types/board";


export const saveAdminBoardApi = async (formData: FormData) => {
    const modal = getModalInstance()
    try {
        const httpSuccess: HttpSuccess = {
            200: () => {
                modal!.alert(
                    () => {
                        window.location.reload()
                    },
                    '알림',
                    '게시글이 수정되었습니다.'
                )
            },
        }
        const httpAlert: HttpAlert = {
            403: () => {
                modal!.alert(() => {}, '경고', '수정 권한이 없습니다.')
            },
        }

        // return await putRequest('/board', httpSuccess, httpAlert, formData, {})
    } catch (e) {
        console.log(e)
    }
}

export const adminUpdateBoardService = async (formData: FormData) => {
    const modal = getModalInstance()
    try {
        const httpSuccess: HttpSuccess = {
            200: () => {
                modal!.alert(
                    () => {
                        window.location.reload()
                    },
                    '알림',
                    '게시글이 수정되었습니다.'
                )
            },
        }
        const httpAlert: HttpAlert = {}

        // return await putRequest('/admin/board', httpSuccess, httpAlert, formData, {})
    } catch (e) {
        console.log(e)
    }
}

export const deleteBoardService = async (boardId: string) => {
    const modal = getModalInstance()
    try {
        const httpSuccess: HttpSuccess = {
            200: () => {
                modal!.alert(
                    () => {
                        window.location.reload()
                    },
                    '알림',
                    '게시글이 삭제되었습니다.'
                )
            },
        }
        const httpAlert: HttpAlert = {
            403: () => {
                modal!.alert(() => {}, '경고', '삭제 권한이 없습니다.')
            },
        }

        return await deleteRequest(
            '/board/' + boardId,
            httpSuccess,
            httpAlert,
            {}
        )
    } catch (e) {
        console.log(e)
    }
}

export const getBoardListService = async (searchQuery: BoardDto.Search) => {
    const modal = getModalInstance()
    try {
        const httpSuccess: HttpSuccess = {}
        const httpAlert: HttpAlert = {}
        const config = {
            params: searchQuery,
        }

        return await getRequest<BoardDto.Page>(
             '/board/list',
            httpSuccess,
            httpAlert,
            config
        )
    } catch (e) {
        console.log(e)
    }
}

export const index = async (boardId: string, isAdmin?: boolean) => {
    const modal = getModalInstance()
    try {
        const httpSuccess: HttpSuccess = {}
        const httpAlert: HttpAlert = {
            410: () => {
                modal!.alert(() => {}, '알림', '삭제된 게시물입니다.')
            },
        }

        return await getRequest<BoardDto.Response>(
            (isAdmin ? '/admin/board/' : '/board/') + boardId,
            httpSuccess,
            httpAlert,
            {}
        )
    } catch (e) {
        console.log(e)
    }
}

export const saveAnswerService = async (answer: any) => {
    const modal = getModalInstance()

    try {
        const httpSuccess: HttpSuccess = {
            200: () => {
                modal!.alert(
                    () => {
                        window.location.reload()
                    },
                    '알림',
                    '답변이 저장되었습니다.'
                )
            },
        }
        const httpAlert: HttpAlert = {}

        return await postRequest(
            '/admin/answer',
            httpSuccess,
            httpAlert,
            answer,
            {}
        )
    } catch (e) {
        console.log(e)
    }
}
