import React from 'react';
import styles from './index.module.scss'

export  interface  RowOption{
    rowList: React.ReactNode[];
    onClick?:()=>void;
}
interface ListTemplateProps {
    header: React.ReactNode;
    row: RowOption[];
    footer?: React.ReactNode;
    extraClass?:string;
}

const ListTemplate: React.FC<ListTemplateProps> = ({header, row,footer,extraClass}) => {
    return (
        <table className={styles.listTable +' '+extraClass}>
            <thead>{header}</thead>
            <tbody>
            {row.map((option, index) => (
                <tr key={index} onClick={option.onClick}>
                    {option.rowList.map((node, idx) => node)}
                </tr>
            ))}
            </tbody>
            {footer &&
            <tfoot>
               {footer}
            </tfoot>
            }
        </table>
    );
};

export default ListTemplate;
