import {useLocation} from "react-router-dom";
import {getModalInstance} from "@Component/common/Modal/ModalProvider";
import {useEffect, useState} from "react";
import {UserDto} from "@Types/user";
import {checkUserInfo} from "@Service/user/UserService";

import {MypageOrders} from "@Component/domains/user/orders/MypageOrders";
import {MypageBoard} from "@Component/domains/user/activity/MypageBoard";
import {TabBar} from "@Component/layout/TabBar";
import MainFrame from "@Component/layout/MainFrame";
import {MypageAccount} from "@Component/domains/user/account/MypageAccount";


export const MyPage = () => {
    const location = useLocation()
    const path = location.pathname
    const modal = getModalInstance()
    const [userInfo, setUserInfo] = useState<UserDto.Info>()
    const [pageTitle, setPageTitle] = useState<string>()
    useEffect(() => {
        document.title = 'BROSPO 마이페이지'

        checkUserInfo(setUserInfo, true)
    }, [])


    const myPageTabOptions = [
        { label: '계정관리', link: '/mypage/account' },
        { label: '주문확인', link: '/mypage/orders' },
        { label: '활동 및 문의', link: '/mypage/activity' },
    ];


    return (
        <MainFrame className="mypage" maxWidth={1000}>
            <TabBar options={myPageTabOptions} />
            <h2 className="page-title">{pageTitle}</h2>
            {path == '/mypage/account' && (
                <MypageAccount userInfo={userInfo!} />
            )}
            {path == '/mypage/orders' && <MypageOrders />}
            {path == '/mypage/activity' && <MypageBoard />}
        </MainFrame>
    )
}
