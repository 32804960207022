import {ProductDto} from "@Types/product";
import {UserDto} from "@Types/user";
import {AnswerDto} from "@Types/answer";

export namespace BoardDto {

    export interface Save {
        boardId?: string; // Optional because it's not always required
        category: number;
        title: string;
        content: string;
        reviewRating?: string;
        productId?: string;
        isHidden?:boolean;
    }

    export interface Search {
        category: number;
        page: number;
        rowSize: number;
        productId?: string;
        userId?: number;
        answer?: string;
    }

    export interface Response {
        boardId: string;
        answer?: AnswerDto.Response;
        category: number;
        title: string;
        content: string;
        count: number;
        state: string;
        reviewRating?: string;
        product?: ProductDto.Response;
        saveDate: string; // LocalDateTime can be represented as a string
        user?: UserDto.Info;
        imageUrl?: string[];
    }

    export interface Page {
        content?: Response[];
        totalElements?: number;
    }
}


export const mapResponseToSave = (response: BoardDto.Response): BoardDto.Save => {
    return {
        boardId: response.boardId,
        category: response.category,
        title: response.title,
        content: response.content,
        reviewRating: response.reviewRating,
        productId: response.product?.productId, // Optional chaining to handle undefined product
        isHidden: response.state == '2'
    };
};
