import {useEffect, useState} from "react";

import {setWishicon} from "@Service/wish/WishService";
import {CartDto} from "@Types/cart";
import {getCartListApi} from "@Api/user/cart";
import {CartItem} from "@Component/domains/cart/CartList/CartItem";
import styles from './index.module.scss'
import MainFrame, {ButtonOption} from "@Component/layout/MainFrame";
import {useNavigate} from "react-router-dom";
import {getModalInstance} from "@Component/common/Modal/ModalProvider";
import {ProductDto} from "@Types/product";
import {ProductListItem} from "@Component/domains/product/ProductList";
import CartPrice from "@Component/domains/cart/CartList/CartPrice";


export const CartList = () => {
    const modal = getModalInstance();
    const [cartList, setCartList] = useState<CartDto.TransformedCart[]>()
    const [selectDto, setSelectDto] = useState<any>({})
    const [refresh, setRefresh] = useState<boolean>(false)

    const fetchData = async () => {
        const data = await getCartListApi()
        setCartList(data)
        if(!refresh) {
            await setWishicon()
        }
    }

    useEffect(() => {
        fetchData()
    }, [])

    useEffect(() => {
        if(refresh){
            fetchData()
            setRefresh(false)
        }
    }, [refresh])

    useEffect(() => {
        console.log(cartList)
    }, [cartList])



    return (
        <MainFrame className={styles.cartList}  marginZero={true}>
            {cartList && cartList?.length > 0 ? (<div>
                    {cartList?.map((cart, index) => (
                        <CartItem key={cart.product.productId || index} cartItem={cart} selectDto={selectDto} setSelectDto={setSelectDto} setRefresh={setRefresh}/>
                    ))}
                </div>) :
                (<div className="empty-view">장바구니가 비어있습니다.</div>)}
            <CartPrice cartList={cartList} selectDto={selectDto} setSelectDto={setSelectDto} setRefresh={setRefresh}/>
        </MainFrame>)
}