

export const carrierNameMapping: Record<string, string> = {
    "kr.epost": "우체국",
    "kr.cjlogistics": "CJ 물류",
    "kr.lotte": "롯데",
    "kr.hanjin": "한진",
    "kr.logen": "로젠",
    "kr.kdexp": "경동",
    "kr.cvsnet": "GS 우편함"
};



export const bankNames = [
    "KEB 하나",
    "KB국민",
    "신한",
    "우리",
    "농협",
    "씨티",
    "스탠다드차타드",
    "HSBC",
    "기업",
    "SC제일",
    "삼성",
    "KDB산업",
    "부산",
    "대구",
    "제주",
    "신협",
    "기타"
];

// 라벨-값 형식으로 변환
export const bankOptions = bankNames.map(name => ({
    label: name,
    value: name,
}));
