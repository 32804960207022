import {ProductListItem} from "@Component/domains/product/ProductList";
import {SizeCounter} from "@Component/domains/product/ProductInfo/sizeCounter";
import {ProductIcon} from "@Component/domains/product/ProductList/ProductIcon";
import React, {SetStateAction, useEffect, useState} from "react";
import {CartDto} from "@Types/cart";
import {deleteCartApi, insertCartApi} from "@Api/user/cart";
import {OrderDto} from "@Types/order";
import styles from './index.module.scss'
import {getModalInstance} from "@Component/common/Modal/ModalProvider";
import CheckBox from "@Component/common/Input/CheckBox";
import Button from "@Component/common/Input/Button";
import {ProductDto} from "@Types/product";
import SizeSelector from "@Component/domains/product/ProductInfo/SizeSelector";


interface Props {
    cartItem: CartDto.TransformedCart
    selectDto?: any
    setSelectDto?: React.Dispatch<SetStateAction<any>>
    setRefresh?: React.Dispatch<SetStateAction<boolean>>
}

export const CartItem: React.FC<Props> = ({cartItem, selectDto, setSelectDto, setRefresh}) => {
    const modal = getModalInstance();
    const [itemDto, setItemDto] = useState<{ size: string, item: OrderDto.Item[] }>({size: '', item: []});
    const [prevState, setPrevState] = useState({size: '', itemCount: 0});

    useEffect(() => {

        // 상품의 사이즈와 각 아이템의 카운트 정보를 추출
        const tempSizeList: string[] = cartItem.sizes.map(item => item.size!);
        const size = tempSizeList.join(',');
        const itemCount = cartItem.sizes.reduce((sum, item) => sum + (item.count || 0), 0); // 전체 카운트 합계

        const tempDto = {
            size: size,
            item: cartItem.sizes
        };

        setItemDto(tempDto);
        setPrevState({size, itemCount}); // 이전 상태에 사이즈와 아이템 카운트를 저장
    }, [cartItem]);

    // 사이즈나 아이템 카운트가 변경될 때 refresh 호출
    useEffect(() => {
        const currentItemCount = itemDto.item.reduce((sum, item) => sum + (item.count || 0), 0); // 현재 아이템 카운트 합계

        console.log(currentItemCount)
        console.log(prevState)
        // 이전 상태와 비교해서 사이즈 또는 카운트가 변경되었는지 확인
        if (prevState.size !== itemDto.size || prevState.itemCount !== currentItemCount) {
            setPrevState({size: itemDto.size, itemCount: currentItemCount}); // 변경된 상태 저장
            if (setRefresh) {
                setRefresh(true); // 사이즈나 카운트가 변경되면 refresh 트리거
            }
        }
    }, [itemDto, cartItem]); // 사이즈와 아이템 리스트가 변경될 때 실행

    if (!itemDto || itemDto.item.length === 0) {
        return null;
    }

    return (
        <div className={styles.cartItem}>
            {selectDto && setSelectDto && (
                <div className={styles.action}>
                    <CheckBox label={'선택'} data={selectDto} setData={setSelectDto} name={cartItem.product.productId!}/>
                    <Button name={'옵션추가'} onClick={() => {
                        modal?.popup(
                            <AddOption product={cartItem.product}/>,
                            '800px',
                            async () => {
                                if (setRefresh) {
                                    setRefresh(true);
                                }
                            }
                        )
                    }}/>
                </div>
            )}
            <ProductListItem product={cartItem.product} mode={'cart'}/>
            <div className={styles.cartInfo}>
                <ProductIcon product={cartItem.product}/>
                <SizeCounter data={itemDto} setData={setItemDto} product={cartItem.product} cart={true}/>
            </div>
            <div className={styles.removeButton} onClick={() => {
                modal?.confirm(async () => {
                    const tempDto: CartDto.Delete[] = [{
                        productId: cartItem.product.productId
                    }];
                    await deleteCartApi(tempDto);
                    setItemDto({item: [], size: ''});
                    if (setRefresh) {
                        setRefresh(true);
                    }
                }, '확인', '해당 상품을 삭제하시겠습니까?')
            }}>
                ✕
            </div>
        </div>
    );
};


const AddOption = (props: { product: ProductDto.Response }) => {
    const modal = getModalInstance()
    const [itemDto, setItemDto] = useState({
        size: '',
        item: []
    })

    useEffect(() => {
        if (itemDto.size) {
            const cartItem: OrderDto.Item[] = [{
                size: itemDto.size,
                count: 1,
                product: props.product
            }]
            insertCartApi(cartItem, true)
            modal?.alert(() => {
                modal?.closeModal()
            }, '알림', '장바구니에 추가되었습니다.')

        }
    }, [itemDto]);


    return (<div>
        <h4 style={{marginBottom: '20px', textAlign: 'left'}}>옵션을 선택해주세요.</h4>
        <ProductListItem product={props.product}/><br/>
        <SizeSelector data={itemDto} setData={setItemDto} productSize={props.product.size}/>
    </div>)
}




