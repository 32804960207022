import {useEffect, useState} from "react";

import {setWishicon} from "@Service/wish/WishService";
import {CartDto} from "@Types/cart";
import {getCartListApi} from "@Api/user/cart";
import {CartItem} from "@Component/domains/cart/CartList/CartItem";
import styles from './index.module.scss'
import MainFrame, {ButtonOption} from "@Component/layout/MainFrame";
import {useNavigate} from "react-router-dom";
import {getModalInstance} from "@Component/common/Modal/ModalProvider";
import {ProductDto} from "@Types/product";
import {ProductListItem} from "@Component/domains/product/ProductList";

export const CartListModal = () => {
    const navigate = useNavigate();
    const modal = getModalInstance()
    const [cartList, setCartList] = useState<CartDto.TransformedCart[]>([])

    const action: ButtonOption[] = [
        {
            name: '구매하기',
            onClick: () => {
                navigate('/order/cart')
                modal?.closeModal()
            }
        }
    ]
    const fetchData = async () => {
        const data = await getCartListApi()
        setCartList(data)
        await setWishicon()
    }

    useEffect(() => {
        fetchData()
    }, [])

    return (
        <MainFrame className={styles.cartModal} title={'장바구니목록'} marginZero={true} action={action}>
            {cartList && cartList?.length > 0 ? (<div className="cart-list">
                    {cartList?.map((cart, index) => (
                        <CartItem key={cart.product.productId || index} cartItem={cart}/>
                    ))}
                </div>) :
                (<div className="empty-view">장바구니가 비어있습니다.</div>)}
        </MainFrame>
    )
}