import React, {useEffect, useState} from 'react'


import {formatDate, privacyName, stripHTMLTags} from '@Util/stringUtils'
import {getModalInstance} from "@Component/common/Modal/ModalProvider";
import {setWishicon} from "@Service/wish/WishService";
import {deleteBoardApi, getBoardApi} from "@Api/user/board";
import ImageViewList from "@Component/common/Image/ImageView/ImageViewList";
import {ProductListItem} from "@Component/domains/product/ProductList/ProductList";
import {BoardDto} from "@Types/board";
import MainFrame, {ButtonOption} from "@Component/layout/MainFrame";
import styles from './index.module.scss'
import ReviewRating from "@Component/domains/board/ReviewRating";
import {ReviewEditor} from "@Component/domains/board/Edit/ReviewEditor";
import {UserDto} from "@Types/user";
import {getUserInfo} from "@Api/user/account";

export const ReviewView = (props: { boardId: string }) => {
    const modal = getModalInstance()
    const [board, setBoard] = useState<BoardDto.Response>()
    const [userInfo, setUserInfo] = useState<UserDto.Info>()
    const fetchData = async () => {
        if (props.boardId) {
            const response = await getBoardApi(props.boardId)
            if (response) {
                setBoard(response)
                setWishicon()
            }
            setUserInfo(await getUserInfo());
        }
    }

    useEffect(() => {
        fetchData()
    }, [props.boardId])


    const actionButton: ButtonOption[] = [
        ...(board?.user?.userId == userInfo?.userId ? [{
            name: '수정',
            onClick: () => {
                modal?.popup(<ReviewEditor boardId={props.boardId}/>, '800px', () => {
                        fetchData()
                    }
                )
            },
        }] : []),
        ...(board?.user?.userId == userInfo?.userId ? [{
            name: '삭제',
            onClick: () => {
                modal?.confirm(()=>{
                    deleteBoardApi(props.boardId!)
                },'확인','삭제하시겠습니까?')
            },
        }] : []),
    ]


    return (
        <MainFrame className={styles.boardView} title={'상품 리뷰'} marginZero={true} action={actionButton}>
            <ProductListItem product={board?.product}/>
            <label>
                한줄평
                <div className={styles.info}>
                    <span>작성자 : {privacyName(board?.user?.userName!)}</span>
                    <span>작성일 : {formatDate(board?.saveDate!)}</span>
                </div>
            </label>
            <div className={styles.textarea}>{board?.title}</div>

            <label>
                구매 후기
                <ReviewRating data={board} name={'reviewRating'}/>
            </label>
            <div
                dangerouslySetInnerHTML={{
                    __html: stripHTMLTags(board?.content || ''),
                }}
                className={styles.textarea + ' ' + styles.content}
            ></div>
            <ImageViewList parentClass="board-image-list" imageUrl={board?.imageUrl || []}/>
            <label>
                답변
                {board?.answer && (
                    <div className={styles.info}>
                        <span>작성자 : BROSPO</span>
                        <span>
                            작성일 : 213
                        </span>
                    </div>
                )}
            </label>
            <div
                dangerouslySetInnerHTML={{
                    __html: stripHTMLTags(
                        board?.answer?.content || '답변이 등록되지 않았습니다.'
                    ),
                }}
                className={styles.textarea + ' ' + styles.content}
            ></div>
        </MainFrame>
    )
}
