import MainFrame from "@Component/layout/MainFrame";
import styles from "@Css/form.module.scss";

import {formList} from "./form";
import {saveValid} from "./valid";
import Button from "@Component/common/Input/Button";
import React, {useEffect, useState} from "react";
import {UserDto} from "@Types/user";
import {Agreement} from "@Component/domains/cmm/Document/Agreement/Agreement";
import {Privacy} from "@Component/domains/cmm/Document/Privacy/Privacy";
import CheckBox from "@Component/common/Input/CheckBox";
import {isValid} from "@Util/formUtils";
import {getModalInstance} from "@Component/common/Modal/ModalProvider";
import {formatTime} from "@Util/stringUtils";
import {createAuthTokenApi} from "@Api/cmm/CmmService";
import FormField, {FormFieldProps} from "@Component/layout/FormField";

import {preventLoggedInAccess} from "@Service/user/UserService";

const UserJoinPage = () => {
    const modal = getModalInstance();
    const [saveDto, setSaveDto] = useState<UserDto.Join>({});
    const [tokenArea, setTokenArea] = useState<boolean>(false);
    const [createToken, setCreateToken] = useState<boolean>(true);


    useEffect(() => {
        preventLoggedInAccess()
    }, []);


    useEffect(() => {
        checkData();
    }, [saveDto]);

    // 데이터 유효성 검사
    const checkData = async () => {
        const temp = {...saveDto, authToken: 'temp'}; // authToken 설정
        const isValidData = await isValid(temp, saveValid, false);
        setTokenArea(isValidData);
    };

    // 인증번호 요청 핸들러
    const handleAuthTokenRequest = async () => {
        if (createToken && await createAuthTokenApi({
            loginId: saveDto.loginId,
            phoneNumber: saveDto.phoneNumber
        })) {
            setCreateToken(false);
            const showResultElement = document.getElementById('auth-token-message');
            if (showResultElement) {
                let countDown = 180; // 3분 카운트다운
                showResultElement.style.color = 'blue';
                const timer = setInterval(() => {
                    showResultElement.innerText = formatTime(countDown--);
                    if (countDown < 0) {
                        clearInterval(timer);
                        showResultElement.innerText = '';
                        modal?.alert(() => setCreateToken(true), '알림', '인증시간이 초과되었습니다.', '번호를 다시 발급해주세요');
                    }
                }, 1000);
            }
        } else {
            modal?.alert(() => {
            }, '알림', '인증번호는 3분마다 재발급 가능합니다.');
        }
    };

    return (
        <MainFrame className={styles.form} maxWidth={800} title="회원가입" documentTitle="회원가입">
            <FormField formFieldList={formList} data={saveDto} setData={setSaveDto} validOption={saveValid}/>
            <Agreement height="200px"/>
            <CheckBox label="서비스이용약관 동의" data={saveDto} setData={setSaveDto} name="agreement"
                      extraClass={styles.checkBox}/>
            <Privacy height="200px"/>
            <CheckBox label="개인정보처리방침 동의" data={saveDto} setData={setSaveDto} name="privacy"
                      extraClass={styles.checkBox}/>

            {tokenArea && (
                <React.Fragment>
                    <label htmlFor="auth-token-form">
                        <p> 인증번호 입력<span id="auth-token-message"></span></p>
                        <Button name="인증번호받기" onClick={handleAuthTokenRequest}/>
                    </label>
                    <input
                        type="text"
                        placeholder="AUTH TOKEN"
                        onChange={(event) => {
                            const {value} = event.target as HTMLInputElement;
                            setSaveDto((prev) => ({...prev, authToken: value})); // authToken 업데이트
                        }}
                    />
                </React.Fragment>
            )}

            <Button
                extraClass={styles.halfButton}
                name="회원가입"
                onClick={async () => {
                    if (await isValid(saveDto, saveValid, true)) {
                        if (createToken) {
                            modal?.alert(() => {
                            }, '알림', '인증토큰을 발급/재발급 해주세요.');
                        } else {
                            console.log(saveDto);
                        }
                    }
                }}
            />
        </MainFrame>
    );
};

export default UserJoinPage;
