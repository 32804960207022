import React, {useEffect} from 'react'
import './InquiryPage.css'
import InquiryList from "@Component/domains/board/List/InquiryList";

export const AdminInquiryPage = () => {
    useEffect(() => {
        document.title = 'BROSPO 상품문의 관리'
    }, [])
    return (
        <div className={'inquiry-page'}>
            <h2 className="page-title">상품 문의</h2>
            <InquiryList  isAdmin={true} />
        </div>
    )
}
