import {useEffect, useState} from "react";
import {checkUserInfo, kakaoLogin, preventLoggedInAccess} from "@Service/user/UserService";
import {Link} from "react-router-dom";
import ImageTag from "@Component/common/Image/ImageTag";
import kakaoApiImage from "@Image/Api/kakao_login_large_wide.png"
import MainFrame from "@Component/layout/MainFrame";
import styles from "@Css/form.module.scss";
import FormField, {FormFieldProps} from "@Component/layout/FormField";

import {createUserLoginDto, UserDto} from "@Types/user";
import {formList} from "@Page/user/account/UserLoginPage/form";
import {userLoginApi} from "@Api/user/account";

const UserLoginPage = (): JSX.Element => {
    const [loginDto, setLoginDto] = useState<UserDto.Login>(createUserLoginDto)
    const submit = () => {
        userLoginApi(loginDto)
    }

    useEffect(() => {
        preventLoggedInAccess()
    }, []);

    return (
        <MainFrame maxWidth={300} className={styles.form} title={
            '로그인'
        }>
            <FormField formFieldList={formList} data={loginDto} setData={setLoginDto} onKeyDown={submit}/>
            <input type="button" value="로그인" onClick={submit} className={styles.grayButton}/>
            <div className={styles.kakaoImage}>
                <ImageTag
                    src={kakaoApiImage}
                    alt="kakao 로그인"
                    onClick={kakaoLogin}
                />
            </div>
            <div className={styles.flex}>
                <Link to={'/user/join'}>회원가입</Link>
                <Link to={'/user/find'}>아이디/비밀번호 찾기</Link>
            </div>

        </MainFrame>
    )
}

export default UserLoginPage;