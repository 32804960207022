import {getModalInstance} from "@Component/common/Modal/ModalProvider";
import React, {useEffect, useState} from "react";
import {deleteBoardApi, getBoardApi} from "@Api/user/board";
import {setWishicon} from "@Service/wish/WishService";

import {formatDate, privacyName, stripHTMLTags} from "@Util/stringUtils";
import ImageViewList from "@Component/common/Image/ImageView/ImageViewList";
import {UserInfoView} from "@Component/domains/user/account/MypageAccount";
import {ProductListItem} from "@Component/domains/product/ProductList/ProductList";
import {InquiryEditor} from "@Component/domains/board/Edit/InquiryEditor";
import {getUserInfo} from "@Api/user/account";
import {BoardDto} from "@Types/board";
import {UserDto} from "@Types/user";
import MainFrame, {ButtonOption} from "@Component/layout/MainFrame";
import styles from "@Component/domains/board/View/index.module.scss";

export const InquiryView = (props: { boardId: string }) => {
    const modal = getModalInstance()
    const [board, setBoard] = useState<BoardDto.Response>()
    const [userInfo, setUserInfo] = useState<UserDto.Info>()
    const fetchData = async () => {
        if (props.boardId) {
            const response = await getBoardApi(props.boardId)
            if (response) {
                setBoard(response)
                setWishicon()
            }
            setUserInfo(await getUserInfo());
        }
    }

    useEffect(() => {
        fetchData()
    }, [props.boardId])


    const actionButton: ButtonOption[] = [
        ...(board?.user?.userId == userInfo?.userId ? [{
            name: '수정',
            onClick: () => {
                modal?.popup(<InquiryEditor boardId={props.boardId}/>, '800px', () => {
                        fetchData()
                    }
                )
            },
        }] : []),
        ...(board?.user?.userId == userInfo?.userId ? [{
            name: '삭제',
            onClick: () => {
                modal?.confirm(()=>{
                    deleteBoardApi(props.boardId!)
                },'확인','삭제하시겠습니까?')
            },
        }] : []),
    ]


    return (
        <MainFrame className={styles.boardView} title={'상품 문의'} marginZero={true} action={actionButton}>
            <ProductListItem product={board?.product}/>
            <label>
                문의제목
                <div className={styles.info}>
                    <span>작성자 : {privacyName(board?.user?.userName!)}</span>
                    <span>작성일 : {formatDate(board?.saveDate!)}</span>
                </div>
            </label>
            <div className={styles.textarea}>{board?.title}</div>

            <label>
                문의 내용
            </label>
            <div
                dangerouslySetInnerHTML={{
                    __html: stripHTMLTags(board?.content || ''),
                }}
                className={styles.textarea + ' ' + styles.content}
            ></div>
            <ImageViewList parentClass="board-image-list" imageUrl={board?.imageUrl || []}/>
            <label>
                답변
                {board?.answer && (
                    <div className={styles.info}>
                        <span>작성자 : BROSPO</span>
                        <span>
                            작성일 : 213
                        </span>
                    </div>
                )}
            </label>
            <div
                dangerouslySetInnerHTML={{
                    __html: stripHTMLTags(
                        board?.answer?.content || '답변이 등록되지 않았습니다.'
                    ),
                }}
                className={styles.textarea + ' ' + styles.content}
            ></div>
        </MainFrame>
    )
}

export const AdminInquiryView = (props: { boardId: string }) => {
    const modal = getModalInstance()

    const [fileMap, setFileMap] = useState<Map<number, File>>(new Map())
    const [tempFileMap, setTempFileMap] = useState<Map<number, File>>(new Map())
    const [reviewRating, setReviewRating] = useState(0)
    const [board, setBoard] = useState<any | undefined>(undefined)
    const [imageUrl, setImageUrl] = useState<string[]>([])
    const fetchBoard = async () => {
        if (props.boardId) {
            const boardDetail = await getBoardApi(props.boardId, true)
            if (boardDetail) {
                // setBoard(boardDetail.board)
                // setImageUrl(boardDetail.imageUrl)
                setWishicon()
            }
        }
    }

    useEffect(() => {
        fetchBoard()
    }, [props.boardId])

    return (
        <div className="board-view">
            <h2>
                상품 문의
                <div>
                    <input
                        type="button"
                        value="답변 저장"
                        onClick={() => {
                            // const answerDTO: AnswerDTO = {
                            //     boardId: board?.boardId!,
                            //     answerId: board?.answer?.answerId || null,
                            //     content: $('#summernote').summernote('code'),
                            // }
                            // saveAnswerService(answerDTO)
                        }}
                    />
                </div>
            </h2>

            <label>유저정보</label>
            <UserInfoView userInfo={board?.user!}/>
            <label>문의 상품</label>
            <ProductListItem product={board?.product}/>

            <label>
                문의 제목
                <div className="board-info">
                    <span>작성일 : {formatDate(board?.createDate!)}</span>
                </div>
            </label>
            <div className="textarea">{board?.title}</div>

            <label>문의 내용</label>
            <div
                dangerouslySetInnerHTML={{
                    __html: stripHTMLTags(board?.content || ''),
                }}
                className="textarea content"
            ></div>
            <ImageViewList parentClass="board-image-list" imageUrl={imageUrl}/>
            <label>답변 작성</label>
            {/*<Editor*/}
            {/*    content={stripHTMLTags(board?.answer?.content || '')}*/}
            {/*    userSet={true}*/}
            {/*    height={300}*/}
            {/*/>*/}
        </div>
    )
}
