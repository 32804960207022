
import {AdminOrderList} from "@Component/domains/order/OrderList/OrderList";

export const AdminOrderPage = () => {
    return (
        <div className="admin-order-page">
            {/*<OrderTabBar/>*/}
            <AdminOrderList />
        </div>
    )
}
