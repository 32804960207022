import {useEffect, useState} from "react";
import {getModalInstance} from "@Component/common/Modal/ModalProvider";
import {deleteAddressApi, getAddressListApi} from "@Api/user/Address";
import {AddressDto} from "@Types/address";
import {decodeHTML} from "@Util/stringUtils";
import AddressEditor from "@Component/domains/address/Editor";
import MainFrame, {ButtonOption} from "@Component/layout/MainFrame";
import styles from './index.module.scss'
import Button from "@Component/common/Input/Button";

export const AddressForm = (props: {
    setAddress: React.Dispatch<React.SetStateAction<AddressDto.Response| undefined>>
}) => {
    const [addressList, setAddressList] = useState<AddressDto.Response[]>([])

    const modal = getModalInstance()

    const fetchData = async () => {
        setAddressList((await getAddressListApi()) || [])
    }
    useEffect(() => {
        fetchData()
    }, [])

    const buttonOption: ButtonOption[] = [
        ...(addressList.length < 3 ? [{
            name: '추가',
            onClick: () => {
                modal?.popup(
                    <AddressEditor/>,
                    '800px',
                    async () => {
                        await fetchData()
                    }
                )
            }
        }] : [])
    ]
    return (
        <MainFrame title='배송지선택' action={buttonOption}>
            <div>
                {addressList?.length != 0 ? (
                    addressList?.map((address, index) => (
                        <div className={styles.addressItem} key={index}>
                            <h3>
                                <p>{address?.addressNickName}</p>
                                <div>
                                    <Button
                                        name={'선택'}
                                        onClick={() => {
                                            props.setAddress(address)
                                            modal?.closeModal()
                                        }}
                                    />
                                    <Button
                                        name={'수정'}
                                        onClick={() => {
                                            modal?.popup(
                                                <AddressEditor
                                                    address={address}
                                                />,
                                                '800px',
                                                async () => {
                                                    await fetchData()
                                                }
                                            )
                                        }}
                                    />
                                    <Button
                                        name={'삭제'}
                                        onClick={async () => {
                                            modal?.confirm(() => {
                                                deleteAddressApi(
                                                    address.addressId!
                                                )
                                                 fetchData()
                                            }, '확인', '삭제하시겠습니까?')
                                        }}
                                    />
                                </div>
                            </h3>
                            <div>
                                <p>수령인</p>{' '}
                                <span>{address.recipientName}</span>
                            </div>
                            <div>
                                <p> 전화번호</p>
                                <span>{address.recipientContact}</span>
                            </div>
                            <div>
                                <p>주소</p>
                                <span>
                                    {decodeHTML(address.address + ' ' + address.addressDetail|| '') }
                                </span>
                            </div>

                            <div>
                                <p>배송요청사항</p>{' '}
                                <span>{address.deliveryRequest}</span>
                            </div>
                        </div>
                    ))
                ) : (
                    <div className={'empty-view'}>주소 정보가 없습니다.</div>
                )}
            </div>
        </MainFrame>
    )
}
