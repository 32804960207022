// axios의 기본 설정 변경
import axios, {AxiosError, AxiosResponse} from "axios";
import {HttpAlert, HttpSuccess} from "@Types/cmm/HttpCallBack";
import {closeLoading, openLoading} from "@Component/layout/Loading";
import {getModalInstance} from "@Component/common/Modal/ModalProvider";

axios.defaults.baseURL = process.env.BASE_URL
axios.defaults.withCredentials = true

const getRequest = async <T extends {}>(
    url: string,
    httpSuccess?: HttpSuccess,
    httpAlert?: HttpAlert,
    config?: {}
): Promise<T> => {
    openLoading()
    try {
        const response = await api.get<T>(url, config)
        if(httpSuccess){
            successHandler(response, httpSuccess)
        }
        return response.data
    } catch (error) {
        if(httpAlert){
            errorHandler(error as AxiosError, httpAlert)
        }
        throw error
    } finally {
        closeLoading()
    }
}
const postRequest = async <T extends {}>(
    url: string,
    data: any,
    httpSuccess?: HttpSuccess,
    httpAlert?: HttpAlert,
    config?: {}
): Promise<T> => {
    if (!url.includes('check')) openLoading()
    try {
        const response = await api.post<T>(url, data, config)
        if(httpSuccess){
            successHandler(response, httpSuccess)
        }
        return response.data
    } catch (error) {
        if(httpAlert){
            errorHandler(error as AxiosError, httpAlert)
        }
        throw error
    } finally {
        if (!url.includes('check')) closeLoading()
    }
}

const api = axios.create()

const putRequest = async <T extends {}>(
    url: string,
    data: any,
    httpSuccess?: HttpSuccess,
    httpAlert?: HttpAlert,
    config?: {}
): Promise<T> => {
    openLoading()
    try {
        const response = await api.put<T>(url, data, config)
        if(httpSuccess){
            successHandler(response, httpSuccess)
        }
        return response.data
    } catch (error) {
        if(httpAlert){
            errorHandler(error as AxiosError, httpAlert)
        }
        throw error
    } finally {
        closeLoading()
    }
}

const deleteRequest = async <T extends {}>(
    url: string,
    httpSuccess?: HttpSuccess,
    httpAlert?: HttpAlert,
    config?: {}
): Promise<T> => {
    openLoading()
    try {
        const response = await api.delete<T>(url, config)
        if(httpSuccess){
            successHandler(response, httpSuccess)
        }
        return response.data
    } catch (error) {
        if(httpAlert){
            errorHandler(error as AxiosError, httpAlert)
        }
        throw error
    } finally {
        closeLoading()
    }
}

const errorHandler = (error: AxiosError, httpAlert: HttpAlert) => {
    const { response } = error
    if (response) {
        const statusCode = response.status

        const modal = getModalInstance()

        if (modal) {
            if (!(500 in httpAlert)) {
                httpAlert[500] = () => {
                    modal.alert(
                        () => {},
                        '경보',
                        '서버에 오류가 발생했습니다 관리자에게 문의해 주세요',
                        'edwsqa987@gmail.com'
                    )
                }
            }
            if (!(403 in httpAlert)) {
                httpAlert[403] = () => {
                    modal.alert(
                        () => {
                            window.location.href = '/'
                        },
                        '경보',
                        '접근 권한이 없습니다.'
                    )
                }
            }
        }

        if (httpAlert[statusCode]) {
            httpAlert[statusCode]()
        }
    }
}

const successHandler = <T extends {}>(
    response: AxiosResponse<T>,
    httpSuccess: HttpSuccess
) => {
    const statusCode = response.status
    if (httpSuccess[statusCode]) {
        httpSuccess[statusCode](response.data) // 상태 코드에 대한 처리 함수 호출
    }
}

export { getRequest, postRequest, putRequest, deleteRequest }
