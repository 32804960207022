import './MypageOrders.css'
import React from "react";
import {OrderList} from "@Component/domains/order/OrderList/OrderList";

export const MypageOrders = () => {
    return (
        <div className={'mypage-orders'}>
            <h3>주문 조회</h3>
            <OrderList />
        </div>
    )
}
