import React, {useEffect} from 'react'

import './ReviewPage.css'
import ReviewList from "@Component/domains/board/List/ReviewList";

export const AdminReviewPage = () => {
    useEffect(() => {
        document.title = 'BROSPO 상품리뷰 관리'
    }, [])
    return (
        <div className={'review-page'}>
            <h2 className="page-title">상품 리뷰</h2>
            <ReviewList  isAdmin={true} />
        </div>
    )
}
