import {getModalInstance} from "@Component/common/Modal/ModalProvider";
import React, {useEffect, useState} from "react";
import {carrierNameMapping, createOrderUpdateDto, OrderDto} from "@Types/order";
import {updateOrderApi} from "@Api/user/order";
import {AddressForm} from "@Component/domains/address/List";
import {decodeHTML} from "@Util/stringUtils";
import {AddressDto} from "@Types/address";
import {DeliveryTracker} from "@Component/common/DeliveryTracker/DeliveryTracker";
import MainFrame from "@Component/layout/MainFrame";

import styles from './index.module.scss'
import {ProductListItem} from "@Component/domains/product/ProductList";
import RequestItem from "@Component/domains/order/OrderInfo/RequestItem";
import Button from "@Component/common/Input/Button";

interface Props {
    orderDto?: OrderDto.Response,
    setOrderDto?: React.Dispatch<React.SetStateAction<OrderDto.Response | undefined>>
    orderItemList?: OrderDto.TransformedOrder[]
}

export const OrderInfo: React.FC<Props> = ({orderDto, orderItemList, setOrderDto}) => {
    const modal = getModalInstance()
    const [saveDto, setSaveDto] = useState<OrderDto.UserUpdate>(createOrderUpdateDto(orderDto))
    const [addressDto, setAddressDto] = useState<AddressDto.Response>()
    const [moreLoad, setMoreLoad] = useState<boolean>(false)
    const updateAddress = async () => {
        const updatedSaveDto = {
            ...saveDto,
            address: addressDto
        };
        setSaveDto(updatedSaveDto);
        await updateOrderApi(updatedSaveDto)
    }

    useEffect(() => {
        if (addressDto) {
            updateAddress()
            if (setOrderDto) setOrderDto(
                {
                    ...orderDto,
                    ...addressDto
                }
            )
        }
    }, [addressDto])

    return (
        <div>
            {orderDto?.orderState != '주문대기' && <React.Fragment>
                <MainFrame title={<>{orderDto?.orderState} <span>{orderDto?.orderCode}</span></>} hideSiteName={true}
                           className={styles.info} action={[{
                    name: '교환/환불 신청', onClick: () => {
                        setMoreLoad(!moreLoad)
                    }
                }]}>
                    <div className={styles.request}>
                        {orderDto?.orderRequestList
                            ?.slice(0, moreLoad ? orderDto.orderRequestList.length : 5)
                            .map((item, index) => (
                                <RequestItem request={item} key={index}/>
                            ))
                        }
                    </div>
                    {orderDto?.orderRequestList?.length! > 5 && <Button name={moreLoad?'닫기':'전체내역보기'} onClick={()=>{  setMoreLoad(!moreLoad)}}/>}
                </MainFrame>
                <MainFrame className={styles.infoFlex + ' ' + styles.info} title={"결제정보"} hideSiteName={true}>
                    <div>
                        <p>결제수단</p>
                        <span>{orderDto?.paymentType}</span>
                    </div>
                    {orderDto?.paymentType == '계좌이체' && (
                        <>
                            <div>
                                <p>입금계좌안내</p>
                                <span>신한 110-454-160504 손영욱</span>
                            </div>
                            <div>
                                <p>송금계좌</p>
                                <span>
                               {orderDto?.accountCode} {orderDto?.accountHolder} {orderDto?.accountNumber}
                            </span>
                            </div>
                        </>
                    )}
                    <div>
                        <p>결제금액</p>
                        <span>
                        {orderDto?.paymentAmount?.toLocaleString()} 원
                    </span>
                    </div>
                </MainFrame>
            </React.Fragment>
            }
            <MainFrame className={styles.infoFlex + ' ' + styles.info} hideSiteName={true} title={'배송정보'} action={[{
                name: '배송 정보 수정',
                onClick: () => {
                    modal?.popup(
                        <AddressForm
                            setAddress={setAddressDto}
                        />)
                }
            }]}>
                {addressDto || orderDto?.recipientName ? (<>
                    <div>
                        <p>수령인</p>{' '}
                        <span>{addressDto?.recipientName || orderDto?.recipientName}</span>
                    </div>
                    <div>
                        <p> 전화번호</p>
                        <span>{addressDto?.recipientContact || orderDto?.recipientContact}</span>
                    </div>
                    <div>
                        <p>주소</p>
                        <span>
                        {decodeHTML(
                            addressDto ? `${addressDto?.address} ${addressDto?.addressDetail}`
                                : `${orderDto?.address} ${orderDto?.addressDetail}`
                        )}
                    </span>
                    </div>
                    <div>
                        <p>배송요청사항</p>
                        <span>{addressDto?.deliveryRequest || orderDto?.deliveryRequest}</span>
                    </div>
                    {orderDto?.deliveryCode && (<div>
                        <p>배송번호</p>
                        <span
                            className={orderDto?.deliveryCode ? 'link' : ''}
                            onClick={() => {
                                if (orderDto?.deliveryCode) {
                                    modal?.popup(
                                        <DeliveryTracker
                                            deliveryCode={orderDto.deliveryCode!}
                                        />
                                    )
                                }
                            }}
                        >
                        {`${carrierNameMapping[orderDto.deliveryCode]} ${orderDto?.deliveryNumber}`}
                    </span>
                    </div>)}</>) : (<div className={'empty-view'}>배송지를 선택해주세요</div>)}
            </MainFrame>
            <MainFrame className={styles.info + ' ' + styles.order} title={'주문목록'} hideSiteName={true}>
                {orderItemList?.map((orderItem, index) => (
                    <div
                        key={index}
                        onClick={() => {
                            modal?.confirm(
                                () => {
                                },
                                '확인',
                                '상품을 확인하시겠습니까?',
                                '페이지로 이동합니다.'
                            )
                        }}
                    >
                        <ProductListItem product={orderItem.product!} mode={'order'}/>
                        <div className={styles.productCount}>
                            <div>수량 :</div>
                            {orderItem.sizes?.map((size, index) => (
                                <div key={index}>
                                    {size.size?.toUpperCase()} / {size.count}
                                    {index != orderItem.sizes.length - 1 &&
                                        ' ,'}
                                </div>
                            ))}
                        </div>
                    </div>
                ))}
            </MainFrame>

        </div>
    )
}

export default OrderInfo;

// export const AdminOrderInfo = (props: { orderDTO: OrderDTO | undefined }) => {
//     const modal = getModalInstance()
//     const [orderItemList, setOrderItemList] = useState<TransformedOrder[]>()
//     const [order, setOrder] = useState<Order>()
//     const [orderDTO, setOrderDTO] = useState<OrderDTO>()
//     const [userInfo, setUserInfo] = useState<UserDto.Info>()
//
//     useEffect(() => {
//         if (!props.orderDTO?.orderItemList) {
//             return
//         }
//
//         const transformedCartList: TransformedOrder[] = []
//
//         props.orderDTO?.orderItemList.forEach((orderItem) => {
//             if (
//                 !transformedCartList.some(
//                     (item) =>
//                         item.product.productId === orderItem.product.productId
//                 )
//             ) {
//                 transformedCartList.push({
//                     product: {
//                         productId: orderItem.product.productId,
//                         category: orderItem.product.category,
//                         name: orderItem.product.name,
//                         price: orderItem.product.price,
//                         deliveryFee: orderItem.product.deliveryFee,
//                         productCode: orderItem.product.productCode,
//                         productDescription: null,
//                         size: null,
//                     },
//                     sizes: [],
//                 })
//             }
//
//             transformedCartList
//                 .find(
//                     (item) =>
//                         item.product.productId === orderItem.product.productId
//                 )
//                 ?.sizes.push({
//                 size: orderItem.size,
//                 count: orderItem.count,
//             })
//         })
//         setOrderDTO(props.orderDTO)
//         setOrder(props.orderDTO.order)
//         setOrderItemList(transformedCartList)
//         checkUserInfo(setUserInfo)
//         console.log(props.orderDTO)
//     }, [props.orderDTO])
//
//     function exportExcel(): void {
//         let data = [
//             [
//                 'h_주문번호',
//                 'h_수령인',
//                 'h_수령인 전화번호',
//                 'h_배송요청사항',
//                 'h_주소',
//             ],
//             [
//                 order?.orderCode,
//                 order?.recipientName,
//                 order?.recipientContact,
//                 order?.deliveryRequest,
//                 decodeHTML(order?.address || '').replace('///', ' '),
//             ],
//             ['', '', '', '', ''],
//             [
//                 'h_상품명',
//                 'h_상품코드',
//                 'h_사이즈',
//                 'h_개수',
//                 'h_상품페이지 주소',
//             ],
//         ]
//
//         orderItemList?.forEach((orderItem) => {
//             orderItem.sizes.forEach((size, index) => {
//                 const temp: string[] = [
//                     index == 0 ? orderItem.product.name! : '',
//                     index == 0 ? orderItem.product.productCode! : '',
//                     size.size,
//                     size.count,
//                     index == 0
//                         ? 'https://brospo.co.kr/order/product/' +
//                         orderItem.product.productId
//                         : '',
//                 ]
//                 data.push(temp)
//             })
//         })
//
//         generateExcel(data, '주문정보', order?.orderCode + ' 주문정보.xlsx')
//     }
//
//     return (
//         <div className="order-info-form">
//             <h2>
//                 주문 상태 : {order?.orderState} <span>{order?.orderCode}</span>
//             </h2>
//
//
//             <div className="order-item-info">
//                 <h3>
//                     <p>주문목록</p>
//                     <div>
//                         <input
//                             className="bg-white-button"
//                             type="button"
//                             value={'엑셀 다운'}
//                             onClick={exportExcel}
//                         />
//                         <input
//                             className="bg-white-button"
//                             type="button"
//                             value={'주문상태 변경'}
//                             onClick={() => {
//                                 modal?.popup(
//                                     <AdminOrderStatus
//                                         orderDTO={orderDTO}
//                                     />,undefined,undefined,async () => {
//                                         const orderDTO = await getAdminOrderService(order?.orderCode)
//                                         setOrderDTO(orderDTO)
//                                         setOrder(orderDTO?.order)
//                                     }
//                                 )
//                             }}
//                         />
//                     </div>
//                 </h3>
//                 {orderItemList?.map((orderItem, index) => (
//                     <div
//                         className="order-product-item"
//                         style={{cursor: 'pointer'}}
//                         key={index}
//                         onClick={() => {
//                             modal?.confirm(
//                                 () => {
//                                 },
//                                 () => {
//                                 },
//                                 '확인',
//                                 '상품을 확인하시겠습니까?',
//                                 '페이지로 이동합니다.'
//                             )
//                         }}
//                     >
//                         {/*<OrderListItem product={orderItem.product!}/>*/}
//                         <div className="order-product-count">
//                             <div>수량 :</div>
//                             {/*{orderItem.sizes?.map((size, index) => (*/}
//                             {/*    <div key={index}>*/}
//                             {/*        {size.size.toUpperCase()} / {size.count}*/}
//                             {/*        {index != orderItem.sizes.length - 1 &&*/}
//                             {/*            ' ,'}*/}
//                             {/*    </div>*/}
//                             {/*))}*/}
//                         </div>
//                     </div>
//                 ))}
//             </div>
//
//             <div className="recipient-view">
//                 <h3>
//                     <p>배송정보</p>
//                 </h3>
//
//                 <div>
//                     <p>수령인</p>{' '}
//                     <span>{props.orderDTO?.order?.recipientName}</span>
//                 </div>
//                 <div>
//                     <p> 전화번호</p>
//                     <span>{props.orderDTO?.order?.recipientContact}</span>
//                 </div>
//                 <div>
//                     <p>주소</p>
//                     <span>
//                         {decodeHTML(
//                             props.orderDTO?.order?.address || ''
//                         ).replace('///', ' ')}
//                     </span>
//                 </div>
//                 <div>
//                     <p>배송요청사항</p>{' '}
//                     <span>{props.orderDTO?.order?.deliveryRequest}</span>
//                 </div>
//                 <div>
//                     <p>배송번호</p>
//                     <span
//                         className={order?.deliveryCode ? 'link' : ''}
//                         onClick={() => {
//                             if (order?.deliveryCode) {
//                                 modal?.popup(
//                                     <DeliveryTracker
//                                         deliveryCode={order.deliveryCode}
//                                     />
//                                 )
//                             }
//                         }}
//                     >
//                         {order?.deliveryCode ? `${carrierNameMapping[order.deliveryCode.split('///')[0]]} ${order.deliveryCode.split('///')[1]}` : '배송준비중'}
//
//                     </span>
//                 </div>
//             </div>
//             <div className={'order-payment-info'}>
//                 <h3>결제정보</h3>
//                 <div>
//                     <p>결제수단</p>
//                     <span>{order?.paymentType}</span>
//                 </div>
//                 {order?.paymentType == '계좌이체' && (
//                     <>
//                         <div>
//                             <p>입금계좌안내</p>
//                             <span>신한 110-454-160504 손영욱</span>
//                         </div>
//                         <div>
//                             <p>송금계좌</p>
//                             <span>
//                                 {order?.accountHolder} {order?.accountNumber}
//                             </span>
//                         </div>
//                     </>
//                 )}
//
//                 <div>
//                     <p>결제금액</p>
//                     <span>
//                         {' '}
//                         {parseInt(
//                             order?.paymentAmount || ''
//                         ).toLocaleString()}{' '}
//                         원
//                     </span>
//                 </div>
//             </div>
//             {(order?.orderState?.includes('취소') || order?.orderState?.includes('교환') || order?.orderState?.includes('반품')) && (
//                 <div className="request-info">
//                     <h3>
//                         <p>{order?.orderState?.slice(0, 2)}사유</p>
//                     </h3>
//
//                     <div className="order-product-item">
//                         <label>요청 사유</label>
//                         <p>{stripHTMLTags(order?.board?.content!)}</p>
//
//                         <label>답변</label>
//                         {/*<p>{stripHTMLTags(order?.board?.answer ? order.board.answer.content : '아직 답변이 달리지 않았습니다.')}</p>*/}
//                     </div>
//                 </div>
//             )}
//         </div>
//     )
// }
