import {Link, useLocation} from 'react-router-dom'
import './index.css'
import kakaoLogo from '@Image/logo/kakao-logo.png'
import {onErrorImg} from '@Util/imageUtils'
import {scrollToBottom, scrollToTop} from '@Util/viewUtils'
import React, {useEffect, useState} from "react";
import {UserDto} from "@Types/user";
import {getUserInfo} from "@Api/user/account";
import {getModalInstance} from "@Component/common/Modal/ModalProvider";
import {OrderModal} from "@Component/domains/order/OrderModal/OrderModal";
import WishView from "@Component/domains/product/Wish";
import {ViewProduct} from "@Component/domains/product/ViewProduct";
import ImageTag from "@Component/common/Image/ImageTag";
import {CartListModal} from "@Component/domains/cart/CartList/CartModal";

const SideBar = () => {
    const location = useLocation()
    const [userInfo, setUserInfo] = useState<UserDto.Info>()
    const fetchData = async () => {
        const userInfo = await getUserInfo()
        setUserInfo(userInfo)
    }

    useEffect(() => {
        fetchData()
    }, [])
    const handlerMobileSizeBar = (e: React.MouseEvent<HTMLElement>) => {
        const target = e.target as HTMLElement
        const delay = 50
        if (target.classList.contains('icon-plus')) {
            const closeIcons = Array.from(
                document.querySelectorAll('.mobile-side-bar i.close')
            )

            closeIcons.reverse().forEach((icon, index) => {
                setTimeout(() => {
                    icon.classList.remove('close')
                    icon.classList.add('open')
                }, index * delay) // 0.05초 간격으로 실행
            })

            target.style.transform = 'rotate(180deg)'
            target.style.color = 'var(--color-4)'
            target.style.backgroundColor = 'rgba(255, 255, 255, 0.8)'
            target.style.border = '1px solid var(--border-color-2)'
            target.classList.remove('icon-plus')
            target.classList.add('icon-minus')
        } else {
            const closeIcons = Array.from(
                document.querySelectorAll('.mobile-side-bar i.open')
            )

            closeIcons.forEach((icon, index) => {
                setTimeout(() => {
                    icon.classList.remove('open')
                    icon.classList.add('close')
                }, index * delay) // 0.05초 간격으로 실행
            })

            target.style.transform = 'rotate(0deg)'
            target.style.color = 'white'
            target.style.backgroundColor = 'rgba(0,0,0,0.3)'
            target.style.border = 'none'
            target.classList.remove('icon-minus')
            target.classList.add('icon-plus')
        }
    }



    if (
        location.pathname.includes('admin') ||
        location.pathname.includes('order') ||
        (location.pathname.includes('user') &&
            !location.pathname.includes('mypage'))
    ) {
        return null
    } else {
        return (
            <div className="side-bar">
                <div className="pc-side-bar">
                    <i
                        className="icon-truck-1 "
                         onClick={() => {
                            const modal= getModalInstance()
                            if (!userInfo) {
                                modal?.confirm(
                                    () => {
                                        window.location.href = '/user/login'
                                    },

                                    '알림',
                                    '확인을 위해 로그인이 필요합니다.',
                                    '로그인 하시겠습니까?'
                                )
                                return
                            }

                            modal?.popup(<OrderModal /> )
                        }}
                    ></i>

                    <i
                        className="icon-basket "
                         onClick={() => {
                            const modal= getModalInstance()
                            if (!userInfo) {
                                modal?.confirm(
                                    () => {
                                        window.location.href = '/user/login'
                                    },
                                    '알림',
                                    '확인을 위해 로그인이 필요합니다.',
                                    '로그인 하시겠습니까?'
                                )
                                return
                            }

                            modal?.popup(<CartListModal />)
                        }}
                    ></i>
                    <i
                        className="icon-heart-empty"
                         onClick={() => {
                             const modal= getModalInstance()
                            if (!userInfo) {
                                modal?.confirm(
                                    () => {
                                        window.location.href = '/user/login'
                                    },
                                    '알림',
                                    '확인을 위해 로그인이 필요합니다.',
                                    '로그인 하시겠습니까?'
                                )
                                return
                            }

                            modal?.popup(<WishView />)
                        }}
                    ></i>
                    <i
                        className="icon-clock"
                         onClick={() => {
                            const modal= getModalInstance()
                            modal?.popup(<ViewProduct /> )
                        }}
                    ></i>
                    <Link
                        to={'http://pf.kakao.com/_iJGzxj/chat'}
                        target={'_blank'}
                    >
                        <img src={kakaoLogo} onError={(e) => onErrorImg(e)} />
                    </Link>
                    <i className="icon-up-open" onClick={scrollToTop}></i>
                    <i
                        className="icon-down-open close-border "
                        onClick={scrollToBottom}
                    ></i>
                </div>
                <div className="mobile-side-bar">
                    <i
                        className="icon-truck-1 close"
                         onClick={() => {
                            const modal= getModalInstance()
                            if (userInfo?.userName == '') {
                                modal?.confirm(
                                    () => {
                                        window.location.href = '/user/login'
                                    },
                                    '알림',
                                    '확인을 위해 로그인이 필요합니다.',
                                    '로그인 하시겠습니까?'
                                )
                                return
                            }

                            modal?.popup(<WishView /> )
                        }}
                    ></i>
                    <i
                        className="icon-basket close"
                         onClick={() => {
                            const modal= getModalInstance()
                            if (userInfo?.userName == '') {
                                modal?.confirm(
                                    () => {
                                        window.location.href = '/user/login'
                                    },
                                    '알림',
                                    '확인을 위해 로그인이 필요합니다.',
                                    '로그인 하시겠습니까?'
                                )
                                return
                            }

                            modal?.popup(<CartListModal />)
                        }}
                    ></i>
                    <i
                        className="icon-heart-empty  close"
                         onClick={() => {
                            const modal= getModalInstance()
                            if (userInfo?.userName == '') {
                                modal?.confirm(
                                    () => {
                                        window.location.href = '/user/login'
                                    },
                                    '알림',
                                    '확인을 위해 로그인이 필요합니다.',
                                    '로그인 하시겠습니까?'
                                )
                                return
                            }
                            modal?.popup(<WishView />)
                        }}
                    ></i>
                    <i
                        className="icon-clock close"
                         onClick={() => {
                            const modal= getModalInstance()
                            modal?.popup(<ViewProduct />)
                        }}
                    ></i>
                    <i
                        className="icon-plus"
                        onClick={(e) => {
                            handlerMobileSizeBar(e)
                        }}
                    ></i>

                    <Link
                        to={'http://pf.kakao.com/_iJGzxj/chat'}
                        target={'_blank'}
                    >
                        <ImageTag src={kakaoLogo} alt={'카카오 문의하기'}/>
                    </Link>
                    <i className="icon-up-open" onClick={scrollToTop}></i>
                    <i
                        className="icon-down-open  "
                        onClick={scrollToBottom}
                    ></i>
                </div>
            </div>
        )
    }
}

export default SideBar