import './MypageBoard.css'

export const MypageBoard = () => {
    return (
        <div className={'mypage-board'}>
            <h3>내가쓴 리뷰</h3>
            {/*<ReviewList userSearch={true}/>*/}
            <h3>내가쓴 문의</h3>
            {/*<InquiryList  userSearch={true}/>*/}
        </div>
    )
}
