

import { getUserInfo, userLogoutApi,} from '@Api/user/account'
import {getModalInstance} from "@Component/common/Modal/ModalProvider";
import {UserDto} from "@Types/user";

export const kakaoLogin = () => {
    const modal = getModalInstance()

    modal!.alert(() => { }, '알림', '준비중 입니다.')
}


export const checkUserInfo = async (
    setUserInfo?: React.Dispatch<React.SetStateAction<UserDto.Info | undefined>>,
    alert?: boolean
) => {
    const modal = getModalInstance()
    const userInfo = await getUserInfo()

    if (!userInfo) {
        if (alert) {
            modal?.alert(
                () => {
                    window.location.href = '/user/login'
                },
                '알림',
                '잘못된 접근입니다.',
                '이용을 위해 로그인이 필요합니다.'
            )
        }
        return false
    }

    if (setUserInfo) {
        setUserInfo(userInfo)
    } else {
        return true
    }
}


export const preventLoggedInAccess = async () => {
    const modal = getModalInstance();
    const userInfo = await getUserInfo();

    // 이미 로그인된 상태일 경우
    if (userInfo) {
            modal?.alert(
                () => {
                    window.location.href = '/';
                },
                '알림',
                '잘못된 접근입니다.',
                '로그아웃 후 이용해주세요.'
            );
        return false;
    }

    return true;
};
